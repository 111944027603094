import React, { useContext } from "react"
import { Box, Flex, Heading } from "theme-ui"
import { DetailsContext } from "./Details"

export default function Summary({ children, ...props }) {
  const { isActive } = useContext(DetailsContext)
  return (
    <Flex
      as={`summary`}
      role="button"
      aria-expanded="isActive"
      sx={{
        padding: 3,
        backgroundColor: "white",
        outlineColor: "green.6",
        justifyContent: "space-between",
        alignItems: "center",
        // justifyContent: "center",
        "&::-webkit-details-marker": {
          display: "none",
        },
      }}
      {...props}
    >
      <Box sx={{ flexGrow: 1 }}>{children}</Box>
      <Flex
        sx={{
          // display: "inline-block", // allows rotating the pseudo element
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "bold",
          fontFamily: "serif",
          fontSize: 4,
          width: "50px",
          height: "50px",

          transition: `all 200ms ease`,
          transformOrigin: "center",
          transform: isActive ? `rotate(90deg)` : null,
        }}
      >
        ❯
      </Flex>
    </Flex>
  )
}
