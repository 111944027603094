import React from "react"
import { Box, Text } from "theme-ui"

export default function WidgetBox({ title, children }) {
  return (
    <Box>
      <Text
        as={`h2`}
        sx={{
          color: "green.5",
          margin: 0,
          marginBottom: "-12px",
          fontWeight: "bold",
          textAlign: "right",
          fontSize: 5,
          top: 14,
          paddingX: 2,
          zIndex: 0,
        }}
      >
        {title}
      </Text>
      <Box
        sx={{
          padding: 4,
          backgroundColor: "white",
          boxShadow: "default",
          borderRadius: "lg",
          textDecoration: "none",
          transition: `box-shadow 0.2s ease`,
          borderTopWidth: 5,
          borderTopStyle: "solid",
          borderTopColor: "red.3",
        }}
      >
        {children}
      </Box>
    </Box>
  )
}
