import React, { useEffect, useState } from "react"
import { Box, Button, Text, Grid } from "theme-ui"
import Input from "../components/Input"
import WidgetBox from "../components/WidgetBox"
// Den här ska räkna ut kontantinsatsen /  inköpspriset.
// Om kontantinsats / inköpspris < 25 % så är amorteringen 2 %
// Om kontantinsats / inköpspris > 25 % men mindre än 50 % så är amorteringen 1 %
// I exemplet har jag hårdkodat till 2 %
// 	-Björn Wallenberg
// DESSUTOM: Även om kontantinsatsen > 50 % så OM skuldkvoten ((C6-C7) / C5)   >  4,5  ska amorteringen vara 1 procent (Det vill säga, om lånets storlek är 4,5 gånger större än bruttoinkomsten)...
// 	-Björn Wallenberg
export default function BolanKostnad(props) {
  const [yearlySalary, setYearlySalary] = useState(null)
  const [costPrice, setCostPrice] = useState(null)
  const [downPayment, setDownPayment] = useState(null)
  const [interest, setInterest] = useState(0.02)
  const [instalmentRate, setInstalmentRate] = useState(null)
  useEffect(() => {
    const downpaymentRatio = downPayment / costPrice
    const debtRatio = (costPrice - downPayment) / yearlySalary
    if (downpaymentRatio < 0.25) {
      setInstalmentRate(0.2)
    } else if (downpaymentRatio > 0.25 || downpaymentRatio < 0.5) {
      setInstalmentRate(0.1)
    } else if (downpaymentRatio > 0.5 || debtRatio > 4.5) {
      setInstalmentRate(0.1)
    }
  }, [downPayment, costPrice, yearlySalary, setInstalmentRate])
  const instalmentCost = borrowingAmount * instalmentRate

  const interestCost = borrowingAmount * interest
  const borrowingAmount = costPrice - downPayment
  return (
    <WidgetBox title={`Bolåne kalkylator`} {...props}>
      <Grid columns={[1, 2]} gap={20}>
        <Box>
          <Input
            id={"yearlySalary"}
            label={`Din årslön, brutto:`}
            onChange={e => {
              setYearlySalary(e.target.value)
            }}
          ></Input>
          <Input
            id={"costPrice"}
            label={`Bostadens inköpspris:`}
            onChange={e => {
              setCostPrice(e.target.value)
            }}
          ></Input>
          <Input
            id={"downPayment"}
            label={`Kontantinsats`}
            onChange={e => {
              setDownPayment(e.target.value)
            }}
          ></Input>
          <Input
            id={"borrowingAmount"}
            label={`Ditt lånebelopp:`}
            value={borrowingAmount}
          ></Input>
          <Input
            id={"interest"}
            label={`Ränta`}
            size={4}
            value={interest * 100}
            onChange={e => {
              setInterest(e.target.value / 100)
            }}
          ></Input>
        </Box>
        <Box
          sx={{
            backgroundColor: "gray.1",
            padding: 4,
            borderRadius: 4,
            marginY: 2,
          }}
        >
          <Box as={`dl`}>
            <Box sx={{ marginY: 2 }}>
              <Text as={`dt`} sx={{ color: "gray.6", fontWeight: "semibold" }}>
                Räntekostnader:
              </Text>
              <Text as={`dd`} sx={{ textAlign: "end", fontWeight: "semibold" }}>
                {interestCost}kr per år
              </Text>
            </Box>
            <Box sx={{ marginY: 2 }}>
              <Text as={`dt`} sx={{ color: "gray.6", fontWeight: "semibold" }}>
                Amorteringskostnader:
              </Text>
              <Text as={`dd`} sx={{ textAlign: "end", fontWeight: "semibold" }}>
                {instalmentCost}kr per år
              </Text>
            </Box>
            <Box sx={{ marginY: 2 }}>
              <Text as={`dt`} sx={{ color: "gray.6", fontWeight: "semibold" }}>
                Totalsumma, amorteringar och räntor år 1:
              </Text>
              <Text as={`dd`} sx={{ textAlign: "end", fontWeight: "semibold" }}>
                {interestCost + instalmentCost}kr
              </Text>
            </Box>
          </Box>
          <hr />
          se hur vi har räknat
        </Box>
      </Grid>
      <Button
        as={`a`}
        variant={"3D"}
        href={`#`}
        sx={{ width: "full", marginTop: 4 }}
      >
        Se bästa räntan och ansök
      </Button>
    </WidgetBox>
  )
}
