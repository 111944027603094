import React from "react"
import { Box } from "theme-ui"

export default function Input({ label, size, ...props }) {
  return (
    <Box sx={{ paddingY: 2 }}>
      <Box
        as={`label`}
        htmlFor={props.id}
        sx={{ display: "block", fontWeight: "bold", color: "text" }}
      >
        {label}
      </Box>
      <Box
        as={`input`}
        sx={{
          width: size ? null : "full",
          boxShadow: "inner",
          padding: 2,
          appearance: "none",
          borderWidth: 1,
          borderRadius: "default",
          borderStyle: "solid",
          borderColor: "gray.4",
        }}
        size={size}
        {...props}
      ></Box>
    </Box>
  )
}
