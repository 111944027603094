import React from "react"
import { Heading, Text } from "theme-ui"

export function H1(props) {
  return (
    <Heading
      {...props}
      as={`h1`}
      sx={{
        fontWeight: "semibold",
        fontSize: [5, 8],
        marginY: 4,
        color: "gray.8",
      }}
    ></Heading>
  )
}
export function H2(props) {
  return (
    <Heading
      {...props}
      as={`h2`}
      sx={{
        fontWeight: "semibold",
        fontSize: [3, 4],
        // marginTop: 4,
        color: "gray.8",
      }}
    ></Heading>
  )
}
export function P({ ...props }) {
  return <Text {...props} as={`p`} sx={{ fontSize: [1, 2], padding: 3 }}></Text>
}
