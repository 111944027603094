import React from "react"
import { Box } from "theme-ui"

export default function InfoMessage(props) {
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "green.1",
        padding: 3,
        color: "green.9",
        marginBottom: 64,
        "&:after": {
          content: "' '",
          position: "absolute",
          bottom: -30,
          left: 4,
          width: 12,
          height: 16,
          backgroundColor: "green.1",
          transform: "rotate(55deg)",
        },
      }}
      {...props}
    />
  )
}
