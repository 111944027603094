import React from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share"
import { Box, Flex } from "theme-ui"
export default function SocialShare({ url }) {
  return (
    <Flex
      sx={{
        justifyContent: "space-between",
        // backgroundColor: "white",
        padding: 2,
        // position: "sticky",
        // top: [40, 45],
        // borderRadius: "lg",
        // boxShadow: "default",
        backgroundColor: "white",
        fontSize: 0,
        marginBottom: 4,
      }}
    >
      <FacebookShareButton url={url}>
        <Flex sx={{ alignItems: "center" }}>
          <FacebookIcon round size={32} />
          <Box
            as={`span`}
            sx={{
              display: ["none", "inline-block"],
              margin: 2,
              fontFamily: "sans",
            }}
          >
            Facebook
          </Box>
        </Flex>
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <Flex sx={{ alignItems: "center" }}>
          <TwitterIcon round size={32} />
          <Box
            as={`span`}
            sx={{ display: ["none", "inline-block"], margin: 2 }}
          >
            Twitter
          </Box>
        </Flex>
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <Flex sx={{ alignItems: "center" }}>
          <LinkedinIcon round size={32} />
          <Box
            as={`span`}
            sx={{ display: ["none", "inline-block"], margin: 2 }}
          >
            LinkedIn
          </Box>
        </Flex>
      </LinkedinShareButton>
      <EmailShareButton url={url}>
        <Flex sx={{ alignItems: "center" }}>
          <EmailIcon round size={32} />
          <Box
            as={`span`}
            sx={{ display: ["none", "inline-block"], margin: 2 }}
          >
            E-post
          </Box>
        </Flex>
      </EmailShareButton>
    </Flex>
  )
}
