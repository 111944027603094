import React from "react"
import { Box, Flex } from "theme-ui"

export default function BolanTakSnurra() {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 4,
        backgroundColor: "white",
        boxShadow: "default",
        borderRadius: "lg",
        textDecoration: "none",
        transition: `box-shadow 0.2s ease`,
      }}
    >
      <Box
        sx={{
          color: "text",
          fontSize: 2,
          fontWeight: "semibold",
          textAlign: "center",
        }}
      >
        Bolån snurra
      </Box>
    </Flex>
  )
}
