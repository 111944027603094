import React from "react"
import { Flex, Box } from "theme-ui"

export default function CompricerAffiliate() {
  return (
    <Flex
      as={`a`}
      href="https://at.compricer.se/t/t?a=1455341723&as=1554045060&t=2&tk=1"
      target="_blank"
      sx={{
        boxShadow: "default",
        flexDirection: ["column", "row"],
        textDecoration: "none",
        marginBottom: 4,
      }}
    >
      <Box sx={{ padding: 3, color: "gray.8" }}>
        genom att jämföra flera olika bankers räntor kan du få bästa räntan på
        ditt bolån.
      </Box>
      <Box
        as={`img`}
        src="https://track.adtraction.com/t/t?a=1455341723&as=1554045060&t=1&tk=1&i=1"
        width="300"
        height="100"
        border="0"
        alt={`Compricer bolån`}
        sx={{
          flexShrink: 0,
          width: ["240px", null, "300px"],
          height: ["80px", null, "100px"],
          margin: 3,
        }}
      />
    </Flex>
  )
}
