/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
// import Image from "gatsby-image"
import Image from "gatsby-background-image"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Box, Text } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import bullet from "../images/bullet.svg"
import BolanTakSnurra from "../containers/BolanTakSnurra"
import BolanKostnad from "../containers/BolanKostnad"
import { FAQJsonLd } from "gatsby-plugin-next-seo"
import SocialShare from "../components/SocialShare"
import PageContainer from "../components/PageContainer"
import { H1, H2, P } from "../components/Typography"
import Details from "../components/Details"
import Summary from "../components/Summary"
import InfoMessage from "../components/InfoMessage"
import CompricerAffiliate from "../components/CompricerAffiliate"

import { motion } from "framer-motion"

// Provide common components here
const shortcodes = {
  h1: props => <H1 {...props} />,
  h2: props => <H2 {...props} />,
  p: props => <P {...props} />,
  BolanTakSnurra: BolanTakSnurra,
  BolanKostnad: BolanKostnad,
  Details: Details,
  Summary: Summary,
  InfoMessage,
  CompricerAffiliate,
  li: props => (
    <Box
      as={`li`}
      {...props}
      sx={{
        listStyleImage: `url(${bullet})`,
        "&::marker": {
          fontSize: "2rem",
        },
      }}
    />
  ),
}
export const query = graphql`
  query PostQuery($id: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    mdx(id: { eq: $id }) {
      id
      body
      mdxAST
      frontmatter {
        title
        slug
        illustration {
          childImageSharp {
            fluid(maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

function groupIntoSections(acc, current, index, all) {
  const { currentIndex } = acc
  const { type } = current
  if (type === "heading" && index !== 0) {
    acc.currentIndex = currentIndex + 1
  }
  acc.faqs[acc.currentIndex] ||= []
  acc.faqs[acc.currentIndex].push(current)
  return acc
}
export default function PostTemplate({
  data: {
    site: {
      siteMetadata: { siteUrl },
    },
    mdx: {
      mdxAST,
      body,
      frontmatter: { title, illustration, slug },
    },
  },
}) {
  const jsondlFAQ = mdxAST.children
    .reduce(groupIntoSections, {
      currentIndex: 0,
      faqs: [],
    })
    .faqs.map(current => {
      return current.reduce(
        (acc, current) => {
          if (current.type === "heading")
            acc.question = current.children[0].value
          if (current.type === "paragraph")
            acc.answer += current.children[0].value
          if (current.type === "list")
            acc.answer += `<ul>${current.children.map(
              item => `<li>${item.children[0].children[0].value}</li>`
            )}</ul>`

          return acc
        },
        { question: "", answer: "" }
      )
    })
  // console.log(jsondlFAQ)
  // const { mount, current, exit, entry, transitionStatus } = useTransitionState()
  return (
    <motion.div
    // initial={entry.state}
    // animate={
    //   transitionStatus === "exiting" ? exit.state : { opacity: 1, x: 0, y: 0 }
    // }
    // transition={
    //   transitionStatus === "exiting"
    //     ? { durration: exit.length }
    //     : { durration: 0.4 }
    // }
    >
      <Layout>
        <SEO title={title}></SEO>
        <FAQJsonLd questions={jsondlFAQ} />

        <PageContainer>
          <Image
            fadeIn
            alt={title}
            title={title}
            loading={`lazy`}
            aria-hidden={true}
            sx={{
              height: "auto",
              paddingX: 3,
              paddingY: 5,
              marginX: [-3, null, 0],
            }}
            fluid={illustration?.childImageSharp?.fluid}
          >
            <Text
              as={`h1`}
              sx={{
                backgroundColor: "white",
                display: "inline",
                color: "gray.9",
                padding: 2,
                fontSize: [4, 5, 8],
                fontWeight: "bold",
                lineHeight: ["normal", "tight", "normal"],
                boxDecorationBreak: "clone",
                whiteSpace: "pre-wrap",
              }}
            >
              {title}
            </Text>
          </Image>
          <SocialShare />
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{body}</MDXRenderer>
          </MDXProvider>
        </PageContainer>
      </Layout>
    </motion.div>
  )
}
