import React, { useRef, useState, useEffect, createContext } from "react"
import { Box } from "theme-ui"

// details.addEventListener("toggle", event => {
//   if (details.open) {
//     /* the element was toggled open */
//   } else {
//     /* the element was toggled closed */
//   }
// })
export const DetailsContext = createContext(null)
export default function Details({ children, ...props }) {
  const detailsRef = useRef()
  const [active, setActive] = useState(false)
  useEffect(() => {
    const { current } = detailsRef
    function toggle(event) {
      current.open ? setActive(true) : setActive(false)
    }
    current.addEventListener("toggle", toggle)
    return () => {
      current.removeEventListener("toggle", toggle)
    }
  }, [detailsRef])
  return (
    <DetailsContext.Provider value={{ isActive: active }}>
      <Box
        ref={detailsRef}
        as={`details`}
        role="group"
        sx={{
          // padding: 3,
          backgroundColor: "gray.1",
          boxShadow: "default",
          // borderRadius: "lg",
          // borderTop: `1px solid black`,
          // borderBottom: `1px solid black`,
          marginBottom: 3,
          // paddingX: active ? 3 : 0,
          // "[open] summary": {
          //   "-webkit-transition": "all 300ms ease",
          //   transition: "all 300ms ease",
          //   padding: 3,
          // },
        }}
        {...props}
      >
        {children}
      </Box>
    </DetailsContext.Provider>
  )
}
